exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-guides-js": () => import("./../../../src/pages/careers/guides.js" /* webpackChunkName: "component---src-pages-careers-guides-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-opportunities-js": () => import("./../../../src/pages/careers/opportunities.js" /* webpackChunkName: "component---src-pages-careers-opportunities-js" */),
  "component---src-pages-careers-roles-js": () => import("./../../../src/pages/careers/roles.js" /* webpackChunkName: "component---src-pages-careers-roles-js" */),
  "component---src-pages-careers-tools-js": () => import("./../../../src/pages/careers/tools.js" /* webpackChunkName: "component---src-pages-careers-tools-js" */),
  "component---src-pages-child-protection-js": () => import("./../../../src/pages/child-protection.js" /* webpackChunkName: "component---src-pages-child-protection-js" */),
  "component---src-pages-classroom-resources-js": () => import("./../../../src/pages/classroom-resources.js" /* webpackChunkName: "component---src-pages-classroom-resources-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-educators-js": () => import("./../../../src/pages/educators.js" /* webpackChunkName: "component---src-pages-educators-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-js": () => import("./../../../src/pages/industry.js" /* webpackChunkName: "component---src-pages-industry-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-programmes-js": () => import("./../../../src/pages/programmes.js" /* webpackChunkName: "component---src-pages-programmes-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-upload-opportunity-js": () => import("./../../../src/pages/upload-opportunity.js" /* webpackChunkName: "component---src-pages-upload-opportunity-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-discipline-js": () => import("./../../../src/templates/discipline.js" /* webpackChunkName: "component---src-templates-discipline-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-listing-js": () => import("./../../../src/templates/news-listing.js" /* webpackChunkName: "component---src-templates-news-listing-js" */),
  "component---src-templates-opportunity-js": () => import("./../../../src/templates/opportunity.js" /* webpackChunkName: "component---src-templates-opportunity-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-role-js": () => import("./../../../src/templates/role.js" /* webpackChunkName: "component---src-templates-role-js" */)
}

